import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';


import './App.css';
import Home from './Components/Pages/Home/Home';
import TokenInfo from './Components/Pages/TokenInformation/TokenInfo';
import Community from './Components/Pages/Community/Community';
import NotFound from './Components/Pages/NotFound';
import Footer from './Components/Pages/Footer/Footer';
import Navbar from './Components/Navbar/Navbar'
import Faqs from './Components/Pages/FAQ\'s/Faqs';
import Blogs from './Components/Pages/Blogs/Blogs';
import Community1 from './Components/Pages/Community1/Community1';
import Ecosystem from './Components/Pages/Ecosystem/Ecosystem';
import CommingSoon from './Components/Pages/CommingSoon/CommingSoon';


// const Home = lazy(() => import('./Components/Pages/Home/Home'));
// const ProjectInfo = lazy(() => import('./Components/Pages/ProjectInformation/ProjectInfo'));
// const TokenInfo = lazy(() => import('./Components/Pages/TokenInformation/TokenInfo'));
// const Community = lazy(() => import('./Components/Pages/Community/Community'));
// const NotFound = lazy(() => import('./Components/Pages/NotFound'));
// const Footer = lazy(() => import('./Components/Pages/Footer/Footer'));
// const Navbar = lazy(() => import('./Components/Navbar/Navbar'));
// const Faqs = lazy(() => import('./Components/Pages/FAQ\'s/Faqs'));
// const UserContextProvider = lazy(() => import('./Components/Context/UserContextProvider'));
// const Blogs = lazy(() => import('./Components/Pages/Blogs/Blogs'));

function App() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
        {/* <Navbar /> */}
        <Routes>
          {/* <Route path='/' element={<Home/>} />
          <Route path='/developers' element={<TokenInfo />}/>
          <Route path='/governance' element={<Community />}/>
          <Route path='/community' element={<Community1 />} />
          <Route path='/faqs' element={<Faqs />} />
          <Route path='/blogs' element={<Blogs />} />
          <Route path='/ecosystem' element={<Ecosystem />} /> */}
          <Route path='/' element={<CommingSoon/>}/>
          <Route path="*" element={<NotFound />} />
        </Routes>
        {/* <Footer /> */}
    </Suspense>
  );
}

export default App;
