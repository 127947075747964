import React from 'react'
import { FaArrowLeftLong } from "react-icons/fa6";
import './CommingSoon.css'

export default function CommingSoon() {

  const goBack = () => {
    window.history.back();
    // console.log("i am going back")
  }

  return (
    <section className='coming_soon_container'>
      <div className='comming_header'>
        <div className='text_content'>
          <span className='text_one'>Bluedex Landing page</span> <br />
          <span className='text_two'>Coming <br /> Soon</span> <br />
          {/* <span className='text_three'>Stay Tuned!</span> */}
          <div>
            <button onClick={goBack} className='back-btn'><FaArrowLeftLong className='me-2' /> <span className='btn-span'>Go Back</span></button>
          </div>

        </div>

      </div>


    </section>
  )
}
